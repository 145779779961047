import { SENDMAIL_FAIL, SENDMAIL_REQUEST, SENDMAIL_SUCCESS, SENDMAIL_RESET } from "../constants/mailConstants"

export const mailReducer = (state = {}, action) => {
    switch (action.type) {

        case SENDMAIL_REQUEST:
            return {
                loading: true
            }

        case SENDMAIL_SUCCESS:
            return {
                loading: false,
                result: action.payload,
            }

        case SENDMAIL_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case SENDMAIL_RESET:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}