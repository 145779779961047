import Layout from '@/components/Layout'
import Link from 'next/link';
import { wrapper } from '@/redux/store'
import { useStore } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import '../styles/bootstrap.min.css'
import '../styles/globals.css'
import ProgressBar from "@badrap/bar-of-progress";
import { useRouter } from 'next/router';
import CookieConsent from 'react-cookie-consent';
import React, { useEffect } from 'react';
import Script from 'next/script';
import { GOOGLE_ANALYTICS } from '@/config/index';
import { pageview, userId } from '@/lib/gtag'
import { SessionProvider } from "next-auth/react"

const progress = new ProgressBar({
  size: 4,
  color: '#5bb62b',
  class: 'bar-of-progress',
  delay: 100
});

const MyApp = ({ Component, pageProps: { session, ...pageProps } }) => {
  const store = useStore((state) => state);

  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url) => {
      pageview(url)
      progress.finish();
    }
    router.events.on('routeChangeStart', progress.start);
    router.events.on('routeChangeError', progress.finish);
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('routeChangeStart', progress.start);
      router.events.off('routeChangeError', progress.finish);
    }
  }, [router])

  useEffect(() => { session && userId(session.user.id) }, [session])

  return (
    <PersistGate persistor={store.__persistor} loading={<div>Loading</div>}>
      <SessionProvider session={session}>
        <Layout>
          {
            <>
              <Script
                strategy='afterInteractive'
                src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS}`}
              />
              <Script
                id="gtag-init"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${GOOGLE_ANALYTICS}', {
                    page_path: window.location.pathname,
                  });
                `,
                }}
              />

            </>
          }

          <Component {...pageProps} />
          <CookieConsent
            debug={process.env.NODE_ENV === 'development'}>
            <div style={{ textAlign: 'center' }}>This site uses cookies. see our <Link href="terms-and-conditions"><a>privacy policy</a></Link></div>
          </CookieConsent>
        </Layout>
      </SessionProvider>
    </PersistGate>

  )
}

export default wrapper.withRedux(MyApp)
