import Link from 'next/link'
import { Container, Row, Col } from 'react-bootstrap'

const FooterMinimal = () => {
    return (
        <footer className="px-5 mt-5">
            <Container fluid>

                <Row>
                    <Col sm={12} className='py-5 text-center'>
                        <p>Copyright &copy; Video-Treat</p>

                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default FooterMinimal
