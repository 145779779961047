
import React, { useState, Fragment, useEffect, useRef } from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { useRouter } from 'next/router';
import { Image } from 'react-bootstrap';

const SearchBox = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const router = useRouter();

    const typeHeadRef = useRef();

    const handleSearch = (query) => {
        setIsLoading(true);

        fetch(`/api/products?keyword=${query}`)
            .then((resp) => resp.json())
            .then(({ products }) => {
                const options = products.map((i) => ({
                    image: i.image,
                    slug: i.slug,
                    name: i.name,
                }));

                setOptions(options);
                setIsLoading(false);
            });
    };


    const handleChange = (input) => {
        if (input[0]) {
            router.push(`/products/${input[0].slug}`)
            typeHeadRef.current.clear();
            typeHeadRef.current.blur();
            props.setExpanded(false)
        }
    }
    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    const filterBy = () => true;

    return (
        <AsyncTypeahead
            ref={typeHeadRef}
            filterBy={filterBy}
            id="search-form"
            isLoading={isLoading}
            labelKey="name"
            minLength={2}
            onSearch={handleSearch}
            onChange={handleChange}
            options={options}
            placeholder="Search for Genius..."
            promptText="Searching"
            searchText="Searching"
            renderMenuItemChildren={(option, props) => (
                <Fragment>
                    <Image
                        alt={option.name}
                        src={option.image}
                        style={{
                            marginRight: '10px',
                            height: '24px',
                            width: '24px'
                        }}
                    />
                    <span>{option.name}</span>
                </Fragment>
            )}
        />
    );
}

export default SearchBox

