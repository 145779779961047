export const NEXT_URL =
    process.env.NEXT_PUBLIC_FRONTEND_URL || 'http://localhost:3000'

export const GOOGLE_ANALYTICS = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS
export const NEXT_JWT_SECRET = process.env.JWT_SECRET || 'abc123'

export const NEXT_CLOUDINARY_NAME =
    process.env.CLOUDINARY_NAME || 'johnaroj'

export const NEXT_CLOUDINARY_API_KEY =
    process.env.CLOUDINARY_API_KEY || '329432636133319'

export const NEXT_CLOUDINARY_SECRET =
    process.env.CLOUDINARY_API_SECRET || 'WANIhVxWVrde0ngz3g5o-bxv1JU'


export const NEXT_SMTP_HOST =
    process.env.SMTP_HOST || 'smtp.hostinger.com'

export const NEXT_SMTP_PORT =
    process.env.SMTP_PORT || 465

export const NEXT_SMTP_USER =
    process.env.SMTP_USER || "info@video-treat.com"

export const NEXT_SMTP_PASS =
    process.env.SMTP_PASS || "VideoTreat007"

export const NEXT_STMP_FROM_NAME =
    process.env.STMP_FROM_NAME || "Video Treat Team"

export const NEXT_STMP_FROM_EMAIL =
    process.env.STMP_FROM_EMAIL || "info@video-treat.com"

// export const NEXT_CENSUS_URL = process.env.CENSUS_URL || 'https://www.dooblocawi.com/client/Survey.aspx?Ticket=NFNI367H&Censusid='