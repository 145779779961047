import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Navbar, Container } from 'react-bootstrap'
import Link from 'next/link'
import Image from 'next/image'

const HeaderMinimal = () => {
    const [headerLogo, setHeaderLogo] = useState('/images/video-treat-black.png')
    const { pathname } = useRouter();

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setHeaderLogo('/images/video-treat-black.png')
        }
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [pathname]);


    return (
        <header>
            <Navbar bg="light" variant='light' expand="lg" collapseOnSelect className='px-5'>
                <Container fluid className="">
                    <Link href='/'>
                        <a className="brand active navbar-brand" style={{ position: 'relative', height: 40, width: 162 }}>
                            <Image src={headerLogo} alt="Video Treat" layout="fill" objectFit='contain' /></a>
                    </Link>


                </Container>
            </Navbar>
        </header>
    )
}

export default HeaderMinimal
