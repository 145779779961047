import { combineReducers } from 'redux'
import { productListReducer, productDetailsReducer, productDeleteReducer, productCreateReducer, productUpdateReducer, productReviewCreateReducer, productTopRatedReducer, productAdminDetailsReducer, productAdminListReducer } from './productReducers'
import { cartReducer } from './cartReducers'
import { userLoginReducer, userRegisterReducer, userDetailsReducer, userUpdateProfileReducer, userListReducer, userDeleteReducer, userUpdateReducer, userLoadReducer, userForgotPasswordReducer, userCreateReducer, userUpdatePasswordReducer } from './userReducers'
import { orderCreateReducer, orderDetailsReducer, orderPayReducer, orderListMyReducer, orderListReducer, orderDeliverReducer, orderCheckoutReducer } from './orderReducers';
import { categoryCreateReducer, categoryDeleteReducer, categoryDetailsReducer, categoryListReducer, categoryUpdateReducer } from './categoryReducers';
import { geniusRegisterReducer } from './geniusReducers';
import { mailReducer } from './mailReducers';
import { bookingListMyReducer } from './bookingReducers';

const reducers = combineReducers({
    productList: productListReducer,
    productAdminList: productAdminListReducer,
    productDetails: productDetailsReducer,
    productAdminDetails: productAdminDetailsReducer,
    productDelete: productDeleteReducer,
    productCreate: productCreateReducer,
    productUpdate: productUpdateReducer,
    productReviewCreate: productReviewCreateReducer,
    productTopRated: productTopRatedReducer,
    cart: cartReducer,
    userForgotPassword: userForgotPasswordReducer,
    userLogin: userLoginReducer,
    userLoad: userLoadReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,
    userUpdatePassword: userUpdatePasswordReducer,
    userCreate: userCreateReducer,
    orderCreate: orderCreateReducer,
    orderDetails: orderDetailsReducer,
    orderPay: orderPayReducer,
    orderCheckout: orderCheckoutReducer,
    orderDeliver: orderDeliverReducer,
    orderListMy: orderListMyReducer,
    orderList: orderListReducer,
    bookingListMy: bookingListMyReducer,
    categoryDetails: categoryDetailsReducer,
    categoryList: categoryListReducer,
    categoryDelete: categoryDeleteReducer,
    categoryCreate: categoryCreateReducer,
    categoryUpdate: categoryUpdateReducer,
    geniusRegister: geniusRegisterReducer,
    mail: mailReducer,

})

export default reducers