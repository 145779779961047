import Link from 'next/link'
import { Container, Row, Col } from 'react-bootstrap'

const Footer = () => {
    return (
        <footer className="px-5 mt-5">
            <Container fluid>
                <Row style={{ display: 'flex', paddingTop: 30 }}>
                    <Col md={2}>
                        <Link href='/how-does-it-work'>
                            <a className="footer-links">How does it work?</a>
                        </Link>
                    </Col>
                    <Col md={1}>
                        <Link href='/faq'>
                            <a className="footer-links">FAQ</a>
                        </Link>
                    </Col>
                    <Col md={1}>
                        <Link href='/about-us'>
                            <a className="footer-links" >About us</a>
                        </Link>
                    </Col>
                    <Col md={1}>
                        <Link href='/contact'>
                            <a className="footer-links">Contact</a>
                        </Link>
                    </Col>
                    <Col md={1}>
                        <Link href='/terms-and-conditions'>
                            <a className="footer-links">Terms</a>
                        </Link>

                    </Col>
                    <Col className="d-flex justify-content-start align-items-center" sm={12} md={6}>
                        <div>
                            <div style={{ marginBottom: 10 }}>
                                <Link href="https://www.facebook.com/video.treat">
                                    <a> <i className="bi bi-facebook"></i></a>
                                </Link>
                                <Link href="https://www.instagram.com/video.treat">
                                    <a><i className="bi bi-instagram"></i></a>
                                </Link>

                            </div>

                            <p style={{ marginBottom: 10 }}>Ready to become a Genius on Video-Treat?</p>
                            <Link href="/register-genius">
                                <a className="btn btn-primary">Register as Genius</a>
                            </Link>
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className='py-5 text-center'>
                        <p>Copyright &copy; Video-Treat</p>
                        <p>KVK 74392239</p>
                        <p>Video-Treat.com</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer
