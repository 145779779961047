import { CART_ADD_ITEM, CART_CLEAR_ITEMS, CART_SAVE_PAYMENT_METHOD, CART_UPDATE_ITEM } from '../constants/cartConstants'

export const cartReducer = (state = { cartItem: null }, action) => {
    switch (action.type) {
        case CART_ADD_ITEM:
            let item = action.payload
            return {
                ...state,
                cartItem: item
            }

        case CART_UPDATE_ITEM:
            let itm = action.payload
            const updatedItm = Object.assign(state.cartItem, itm);

            return {
                ...state,
                cartItem: updatedItm
            }
        case CART_SAVE_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethod: action.payload
            }
        case CART_CLEAR_ITEMS:
            return {
                ...state, cartItem: null
            }
        default:
            return state;
    }
}