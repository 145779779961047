import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import Link from 'next/link'
import { signOut, useSession } from 'next-auth/react'
import SearchBox from './SearchBox'
import Image from 'next/image'
import useBreakpoint from 'hooks/useBreakpoint'

const animateHeader = () => {
    let headerHeight = document.querySelector('header').offsetHeight;
    if (window.scrollY > 70) {
        document.querySelector('.navbar').classList.add('fixed-top');
        document.body.style.paddingTop = headerHeight + 'px';
    } else {
        document.querySelector('.navbar').classList.remove('fixed-top');
        document.body.style.paddingTop = '0';
    }
}


const Header = () => {
    const [headerLogo, setHeaderLogo] = useState('/images/video-treat-logo.png')
    const { pathname } = useRouter();
    const { data: session } = useSession()
    const [expanded, setExpanded] = useState(false)
    const breakpoint = useBreakpoint();
    const [logoWidth, setLogoWidth] = useState(0);
    const [inputStyle, setInputStyle] = useState(null);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setHeaderLogo('/images/video-treat-logo.png')
        }

        if (['sm', 'xs'].includes(breakpoint)) {
            setHeaderLogo('/images/video-treat-icon.png')
            setLogoWidth(40);
            setInputStyle({
                flex: 1,
                marginLeft: breakpoint === 'xs' ? 5 : 20,
                marginRight: breakpoint === 'xs' ? 5 : 40
            })
            document.querySelector('.navbar').classList.add('fixed-top');

        } else {
            setHeaderLogo('/images/video-treat-logo.png')
            setLogoWidth(162);
            setInputStyle({
                flex: 1,
            })
            document.addEventListener('scroll', animateHeader)
        }
        return () => {
            document.removeEventListener('scroll', animateHeader)
        }

    }, [breakpoint])


    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [pathname]);

    return (
        <header id="header">
            <Navbar bg="dark" variant='dark' expand="lg" collapseOnSelect className='px-5'
                onToggle={() => setExpanded(!expanded)}
                expanded={expanded}
            >
                <Container fluid className="">
                    <Link href='/'>
                        <a className="brand active navbar-brand" style={{ position: 'relative', height: 40, width: logoWidth }}>
                            <Image src={headerLogo} alt="Video Treat" layout="fill" objectFit='contain' /></a>
                    </Link>
                    <div style={inputStyle}>
                        <SearchBox setExpanded={setExpanded} />
                    </div>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        {/* <SearchBox setExpanded={setExpanded} /> */}
                        <Nav className="ml-auto">
                            <Link href='/about-us' passHref>
                                <Nav.Link>About Us</Nav.Link>
                            </Link>
                            <Link href='/contact' passHref>
                                <Nav.Link>Contact</Nav.Link>
                            </Link>
                            {
                                (session) ? (
                                    <NavDropdown title={`${session.user.name}`} id='username'>
                                        <Link href='/profile' passHref>
                                            <NavDropdown.Item>Profile</NavDropdown.Item>
                                        </Link>
                                        <Link href='/my-orders' passHref>
                                            <NavDropdown.Item>My Orders</NavDropdown.Item>
                                        </Link>
                                        <NavDropdown.Item onClick={signOut}>Logout</NavDropdown.Item>
                                    </NavDropdown>
                                ) : <Link href='/login' passHref>
                                    <Nav.Link><i className="bi-person-circle" style={{ marginRight: 5 }} />Sign In</Nav.Link>
                                </Link>
                            }
                            {(session?.user.isAdmin) && (
                                <NavDropdown title='Admin' id='adminmenu'>
                                    <Link href='/admin/users' passHref>
                                        <NavDropdown.Item>Users</NavDropdown.Item>
                                    </Link>
                                    <Link href='/admin/categories' passHref>
                                        <NavDropdown.Item>Categories</NavDropdown.Item>
                                    </Link>
                                    <Link href='/admin/products' passHref>
                                        <NavDropdown.Item>Products</NavDropdown.Item>
                                    </Link>
                                    <Link href='/admin/orders' passHref>
                                        <NavDropdown.Item>Orders</NavDropdown.Item>
                                    </Link>
                                </NavDropdown>
                            )}
                        </Nav>
                    </Navbar.Collapse>

                </Container>
            </Navbar>
        </header>
    )
}

export default Header
