import { GENIUS_REGISTER_REQUEST, GENIUS_REGISTER_SUCCESS, GENIUS_REGISTER_FAIL } from '../constants/geniusConstants'


export const geniusRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case GENIUS_REGISTER_REQUEST:
            return { loading: true }
        case GENIUS_REGISTER_SUCCESS:
            return { loading: false, userInfo: action.payload }
        case GENIUS_REGISTER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}