import Head from 'next/head'
import Footer from './Footer'
import Header from './Header'
import { Container } from 'react-bootstrap'
import { useRouter } from 'next/router'
import HeaderMinimal from './HeaderMinimal'
import FooterMinimal from './FooterMinimal'

export default function Layout({ title, keywords, description, children }) {
    const router = useRouter();
    return (
        router.pathname !== '/genius' ?
            <>
                <Head>
                    <title>{title}</title>
                    <meta name='description' content={description} />
                    <meta name='keywords' content={keywords} />
                </Head>
                <Header />
                <main>
                    <Container fluid>
                        {children}
                    </Container>
                </main>
                <Footer></Footer>
            </> : <div className="alt__view"><HeaderMinimal />{children}<FooterMinimal></FooterMinimal></div>
    )
}

Layout.defaultProps = {
    title: 'Welcome To Video-Treat',
    description: 'Request your video-treat',
    keywords: 'Genius, video-treat'
}
